* {
	margin: 0;
	padding: 0;
}

#loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.absolute {
	position: absolute !important;
}

#loader.absolute {
	background: #ffffff;
	opacity: 0.4;
	z-index: 100;
}

@media print {
	.noPrint {
		display: none;
	}

	table {
		border-collapse:collapse;
		border: 1px solid #eeeeee;
		width: 100%;
	}

	th,td {
		border: 1px solid #eeeeee;
		text-align: center;
		padding: 3px 5px;
	}

	h2 {
		text-align: center;
		margin-bottom: 1cm;
	}
}
